import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'
import { styled as styledc } from 'styled-components'

export const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    'background-color': '#131313',
  },
  '& .MuiDialogContent-root': {
  },
  '& .MuiDialogActions-root': {
  },
}))

export const BoxTitleDialog = styled(Box)(() => ({
  paddingTop: '4px',
  paddingBottom: '4px',
  background: `linear-gradient(180deg, #FFF3BF 0%, #E8CC93 45.83%, #BCA578 100%)`,
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
}))

export const DialogsHr = styledc.hr`
  border: none;
  height: 1px;
  color: #D1D1D1;  /* old IE */
  background-color: #D1D1D1;  /* Modern Browsers */
`
