export const DoneIcon = ({ ...props }) => {
  return (
    <svg
      width={props.width ? props.width : '60'}
      height={props.height ? props.height : '60'}
      viewBox="0 0 90 91"
      fill={props.fill ? props.fill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2135_4808)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 45.5C0 33.5653 4.74106 22.1193 13.1802 13.6802C21.6193 5.24106 33.0653 0.5 45 0.5C56.9347 0.5 68.3807 5.24106 76.8198 13.6802C85.2589 22.1193 90 33.5653 90 45.5C90 57.4347 85.2589 68.8807 76.8198 77.3198C68.3807 85.7589 56.9347 90.5 45 90.5C33.0653 90.5 21.6193 85.7589 13.1802 77.3198C4.74106 68.8807 0 57.4347 0 45.5Z"
          fill="url(#paint0_linear_2135_4808)"
        />
        <path
          d="M24 48.5L38 62.5L66 32.5"
          stroke="url(#paint1_linear_2135_4808)"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2135_4808"
          x1="90.0002"
          y1="90.4998"
          x2="-12.3799"
          y2="90.4998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA79" />
          <stop offset="0.619792" stopColor="#FFDA79" stopOpacity="0.79" />
          <stop offset="1" stopColor="#FFDA79" stopOpacity="0.84375" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2135_4808"
          x1="77.9371"
          y1="14.3508"
          x2="33.8162"
          y2="81.7292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_2135_4808">
          <rect width="90" height="90" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>{' '}
    </svg>
  )
}
