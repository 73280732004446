import CloseIcon from '@mui/icons-material/Close'
import { Box, DialogProps, FormControlLabel, Link, Tooltip } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from '@pancakeswap/localization'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import * as React from 'react'
import { slipAddress } from 'utils/helps'
import CTButtonImage from 'views/Component/CTButtonImage'
import { useNetwork } from 'wagmi'
import CTRadio from '../../views/Component/CTRadio'
import { CopyIcon } from '../../views/Component/Icon/CopyIcon'
import { DoneIcon } from '../../views/Component/Icon/DoneIcon'
import { BootstrapDialog, BoxTitleDialog, DialogsHr } from './style'

const styleText = { display: 'block', fontSize: '14px', color: '#E1E1E1' }
const styleText2 = { display: 'block', fontSize: '14px', color: '#FFFFFF' }

const DialogsCreateToken = ({
  open,
  handleClose,
  contract,
  dataForm,
}: {
  open: boolean
  handleClose: (...args: any) => any
  contract: string
  dataForm: any
}) => {
  const { chain } = useNetwork()
  const [openTip, setOpenTip] = React.useState(false)
  const [openTipTow, setOpenTipTow] = React.useState(false)
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body')

  const handleTooltipClose = () => {
    setOpenTip(false)
  }

  const handleTooltipTowClose = () => {
    setOpenTipTow(false)
  }

  const handleTooltipOpen = () => {
    setOpenTip(true)
    setTimeout(()=> {
      setOpenTip(false)
    }, 1000)
  }

  const handleTooltipTwoOpen = () => {
    setOpenTipTow(true)
    setTimeout(()=> {
      setOpenTipTow(false)
    }, 1000)
  }
  const copyAddressContract = (t: string) => {
    navigator.clipboard.writeText(t)
    handleTooltipOpen()
  }

  const copyAddressRecipient = (t: string) => {
    navigator.clipboard.writeText(t)
    handleTooltipTwoOpen()
  }
  const { t } = useTranslation()
  const { isDesktop } = useMatchBreakpoints()

  let stylePaperRoot = {}

  if (isDesktop) {
    stylePaperRoot = {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '90%',
          maxWidth: '730px',
        },
      },
    }
  } else {
    stylePaperRoot = {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '90%',
        },
      },
      '& .MuiDialogContent-root': {
        padding: '5px 10px'
      },
    }
  }

  return (
    <div>
      <BootstrapDialog scroll={scroll} sx={stylePaperRoot} open={open}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: '#FFFFFF' }} />
        </IconButton>
        <DialogContent dividers>
          <Box pl="10px" pr="10px">
            <Box display="flex" justifyContent="center">
              <DoneIcon />
            </Box>
            <Box display="flex" justifyContent="center" sx={{ mt: '32px', textAlign: 'center' }}>
              <BoxTitleDialog
                sx={{
                  display: 'block',
                  fontSize: '20px',
                  fontWeight: 600,
                }}
              >
                {t('Your Contract has been successfully generated')}
              </BoxTitleDialog>
            </Box>
            {/* <Box display="flex" justifyContent="center" sx={{ pt: 1, mt: 1, textAlign: 'center' }}>
              <Box
                sx={{
                  display: 'block',
                  color: '#FFFFFF',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {t('Please review contract information below')}
              </Box>
            </Box> */}
            <Box display="flex" justifyContent="left" sx={{ mt: '32px' }}>
              <Box
                sx={{
                  display: 'block',
                  color: '#FFFFFF',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {t('Token information')}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
              <Box justifyContent="left" sx={styleText}>
                {t('Token Name')}
              </Box>
              <Box justifyContent="right" sx={styleText}>
                {dataForm?.name}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
              <Box justifyContent="left" sx={styleText}>
                {t('Symbol')}
              </Box>
              <Box justifyContent="right" sx={styleText}>
                {dataForm?.symbol}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
              <Box justifyContent="left" sx={styleText}>
                {t('Total supply')}
              </Box>
              <Box justifyContent="right" sx={styleText}>
                {dataForm?.initialSupply}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
              <Box justifyContent="left" sx={styleText}>
                {t('Decimals')}
              </Box>
              <Box justifyContent="right" sx={styleText}>
                {dataForm?.decimals}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
              <Box justifyContent="left" sx={styleText}>
                {t('Contract')}
              </Box>
              <Box justifyContent="right" sx={{ display: 'flex', fontSize: '14px', color: '#9E9E9E' }}>
                <Box justifyContent="right" display="inline" sx={styleText}>
                  <Link
                    target="_blank"
                    sx={{ color: '#FFFFFF' }}
                    href={`${chain?.blockExplorers?.default?.url}/address/${contract}`}
                  >
                    <Box sx={{ wordBreak: 'break-all', maxWidth: '250px', textAlign: 'right' }}>{slipAddress(contract, 10)}</Box>
                  </Link>
                </Box>
                <Box justifyContent="right" sx={{ mt: -0.8, ml: 1, display: 'block' }}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={openTip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied"
                  >
                    <IconButton
                      onClick={() => {
                        copyAddressContract(contract)
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Box sx={{ pt: 2, mb: 2 }}>
              <DialogsHr />
            </Box>
            <Box display="flex" justifyContent="left" sx={{ pt: 1 }}>
              <Box
                sx={{
                  display: 'block',
                  color: '#FFFFFF',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {t('Token configuration')}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', pt: 2 }}>
              <FormControlLabel
                value="female"
                color="#ffffff"
                control={
                  <CTRadio
                    sx={{
                      color: '#9E9E9E',
                    }}
                    checked={dataForm?.canBurn}
                    disabled
                    value="a"
                    name="radio-buttons"
                    size="small"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
                label={<Box sx={!dataForm?.canBurn ? styleText2 : styleText2}>{t('Can Burn')}</Box>}
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                value="female"
                color="#ffffff"
                control={
                  <CTRadio
                    sx={{
                      color: '#9E9E9E',
                    }}
                    checked={dataForm?.canMint}
                    disabled
                    name="radio-buttons"
                    size="small"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
                label={<Box sx={!dataForm?.canMint ? styleText2 : styleText2}>{t('Can Mint')}</Box>}
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                value="female"
                color="#ffffff"
                control={
                  <CTRadio
                    sx={{
                      color: '#9E9E9E',
                    }}
                    checked={dataForm?.canPause}
                    disabled
                    value="a"
                    name="radio-buttons"
                    size="small"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
                label={<Box sx={!dataForm?.canPause ? styleText2 : styleText2}>{t('Can Pause')}</Box>}
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                value="female"
                color="#ffffff"
                control={
                  <CTRadio
                    sx={{
                      color: '#9E9E9E',
                    }}
                    checked={dataForm?.canBlacklist}
                    disabled
                    name="radio-buttons"
                    size="small"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
                label={<Box sx={!dataForm?.canBlacklist ? styleText2 : styleText2}>{t('Can Blacklist')}</Box>}
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                value="female"
                color="#ffffff"
                control={
                  <CTRadio
                    sx={{
                      color: '#9E9E9E',
                    }}
                    checked={dataForm?.applyBrunFee}
                    disabled
                    name="radio-buttons"
                    size="small"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
                label={
                  <Box sx={!dataForm?.applyBrunFee ? styleText2 : styleText2}>{t('Apply Transaction Tax / Fee')}</Box>
                }
              />
            </Box>

            {dataForm?.applyBrunFee && (
              <>
                <Box display="flex" justifyContent="space-between" sx={{ pt: 1 }}>
                  <Box justifyContent="left" sx={{ ...styleText, ml: 4 }}>
                    <Box display="inline" pr="5px">
                      &#x2022;
                    </Box>
                    {t('Recipient address')}
                  </Box>
                  <Box justifyContent="right" sx={{ display: 'flex', fontSize: '14px', color: '#9E9E9E' }}>
                    <Box justifyContent="right" display="inline" sx={styleText}>
                      <Link
                        target="_blank"
                        sx={{ color: '#FFFFFF' }}
                        href={`${chain?.blockExplorers?.default?.url}/address/${dataForm?.recipientAddress}`}
                      >
                        <Box sx={{textAlign: 'right' }}>{slipAddress(dataForm?.recipientAddress, 10)}</Box>
                      </Link>
                    </Box>
                    <Box justifyContent="right" sx={{ mt: -0.8, ml: 1, display: 'block' }}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipTowClose}
                        open={openTipTow}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Copied"
                      >
                        <IconButton
                          onClick={() => {
                            copyAddressRecipient(dataForm?.recipientAddress)
                          }}
                        >
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ pt: 1 }}>
                  <Box justifyContent="left" sx={{ ...styleText, ml: 4 }}>
                    <Box display="inline" pr="5px">
                      &#x2022;
                    </Box>
                    {t('Tax / fee on transfer in basis points (bps)')}
                  </Box>
                  <Box justifyContent="right" sx={{ ...styleText, mr: 4 }}>
                    {dataForm?.txFee}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        <Box display="flex" justifyContent="center" sx={{ pb: 5 }}>
          {isDesktop ? (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/Button.png")',
                backgroundAttachment: 'local',
                backgroundPosition: '50% 45%',
                width: 450,
                height: 50,
                textTransform: 'uppercase',
              }}
              loading={false}
              loadingPosition="start"
              loadingLeft="135px"
              onClick={handleClose}
            >
              <Box
                mr="10px"
                sx={{
                  mr: 2,
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {false ? t('Loading') : t('Done')}
              </Box>
            </CTButtonImage>
          ) : (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/ButtonMobile.png")',
                backgroundAttachment: 'local',
                backgroundPosition: '50% 45%',
                width: 210,
                height: 48,
                textTransform: 'uppercase',
              }}
              loading={false}
              loadingPosition="start"
              loadingLeft="20px"
              onClick={handleClose}
            >
              <Box
                mr="10px"
                sx={{
                  mr: 2,
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {false ? t('Loading') : t('Done')}
              </Box>
            </CTButtonImage>
          )}
        </Box>
      </BootstrapDialog>
    </div>
  )
}

export default DialogsCreateToken
