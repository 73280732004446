import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'

const CTButtonImage = styled(LoadingButton)<{ loadingLeft?: any; colorT?: any } & LoadingButtonProps>(
  ({ loadingLeft, colorT }) => ({
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    textTransform: 'none',

    '&.MuiButtonBase-root:hover': {},
    '&.MuiButtonBase-root': {
      color: colorT,
      fontSize: '18px',
      '&.MuiInputBase-root fieldset': {
        color: `#FFFFFF`,
      },
      '& .MuiFormHelperText-root': {
        color: `#FFFFFF !important`,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: `#FFFFFF !important`,
      },
      '& .MuiLoadingButton-loadingIndicator': {
        marginLeft:  loadingLeft,
        color: '#FFFFFF !important',
      },
      '&.MuiLoadingButton-root.Mui-disabled': {
        color: '#FFFFFF !important',
      },
    },
  }),
)

export default CTButtonImage
