import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { useTranslation } from '@pancakeswap/localization';
import { useMatchBreakpoints } from '@pancakeswap/uikit';

// const steps = ['Basic setting', 'Token configuration', 'Create contract'];


const styleTextGradient = {
  background: 'conic-gradient(from 180deg at 48.5% 50%, #E3A455 26.24999910593033deg, #F6DBA6 88.12500178813934deg, #FFEBC3 270.9470772743225deg, #F0BE79 281.3980793952942deg, #FB9700 328.063280582428deg)',
  'background-clip': 'text',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  'text-transform': 'capitalize',
  fontWeight: '600'
}
export default function StpperLine(
  {
    activeStep,
    steps,
    ...props
  }
) {
  const { t } = useTranslation()

  const { isDesktop } = useMatchBreakpoints()

  return (
    <Box {...props} >
      <Stepper nonLinear activeStep={activeStep} sx={{
          flexDirection: isDesktop? 'row' : 'column',
        '.MuiStepConnector-line': {
          borderColor: '#B1B1B1',
        },
      }}>
        {steps.map((label, index) => (
          <Step key={label}
          >
            <StepButton icon={<></>} >
              <Box fontSize={24} fontWeight={500} sx={(activeStep === index) ? styleTextGradient : { color: '#FFFFFF', textTransform: 'capitalize' }}>{index+1}. {t(label)} </Box>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}