export const logApiToken = (wallet: string,tokenName: string, symbol: string, supply: number,transactionHash: string, contractAddress: string) => {
  const myHeaders: Headers = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  const raw: string = JSON.stringify({
    wallet,
    tokenName,
    symbol,
    supply,
    transactionHash,
    contractAddress,
  })

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }

  fetch(`${process.env.NEXT_PUBLIC_LOG_SERVER}/api/log/token`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log('error', error))
}

export const logApiNft = (wallet: string, nftName: string, symbol: string, collectionSize: number, metadataLink: string, transactionHash: string, contractAddress: string) => {
  const myHeaders: Headers = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  const raw: string = JSON.stringify({
    wallet,
    nftName,
    symbol,
    collectionSize,
    metadataLink,
    transactionHash,
    contractAddress,
  })

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }

  fetch(`${process.env.NEXT_PUBLIC_LOG_SERVER}/api/log/nft`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log('error', error))
}
