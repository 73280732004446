export const CopyIcon = ({ ...props }) => {
  return (
    <svg
      width={props.width ? props.width : '16'}
      height={props.height ? props.height : '19'}
      viewBox="0 0 16 19"
      fill={props.fill ? props.fill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3.6275V13.25C1.99985 14.2021 2.36185 15.1186 3.01256 15.8136C3.66327 16.5086 4.55397 16.93 5.504 16.9925L5.75 17H12.371C12.2159 17.4386 11.9287 17.8184 11.5488 18.087C11.169 18.3557 10.7152 18.4999 10.25 18.5H5C3.80653 18.5 2.66193 18.0259 1.81802 17.182C0.974106 16.3381 0.5 15.1935 0.5 14V5.75C0.499756 5.28451 0.643892 4.8304 0.912542 4.45026C1.18119 4.07012 1.56113 3.78266 2 3.6275ZM13.25 0.5C13.8467 0.5 14.419 0.737053 14.841 1.15901C15.2629 1.58097 15.5 2.15326 15.5 2.75V13.25C15.5 13.8467 15.2629 14.419 14.841 14.841C14.419 15.2629 13.8467 15.5 13.25 15.5H5.75C5.15326 15.5 4.58097 15.2629 4.15901 14.841C3.73705 14.419 3.5 13.8467 3.5 13.25V2.75C3.5 2.15326 3.73705 1.58097 4.15901 1.15901C4.58097 0.737053 5.15326 0.5 5.75 0.5H13.25ZM13.25 2H5.75C5.55109 2 5.36032 2.07902 5.21967 2.21967C5.07902 2.36032 5 2.55109 5 2.75V13.25C5 13.4489 5.07902 13.6397 5.21967 13.7803C5.36032 13.921 5.55109 14 5.75 14H13.25C13.4489 14 13.6397 13.921 13.7803 13.7803C13.921 13.6397 14 13.4489 14 13.25V2.75C14 2.55109 13.921 2.36032 13.7803 2.21967C13.6397 2.07902 13.4489 2 13.25 2Z"
        fill="#F1F1F1"
        fillOpacity="0.945098"
      />
    </svg>
  )
}
