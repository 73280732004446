import CloseIcon from '@mui/icons-material/Close'
import { Box, DialogProps } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from '@pancakeswap/localization'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import React from 'react'
import CTButtonImage from 'views/Component/CTButtonImage'
import ItemAddress from './ItemAddress'
import { BootstrapDialog } from './style'

interface DescriptionWithTxProps {
  description?: string
  tx?: string
}

const styleText = { display: 'block', fontSize: '14px', color: '#9E9E9E' }

const styleTextChecked = { display: 'block', fontSize: '14px', color: '#E6CB82' }

const DialogsWhiteList = ({
  open,
  handleClose,
  whiteList = [],
}: {
  open: boolean
  handleClose: (...args: any) => any
  whiteList: string[]
}) => {
  const { t } = useTranslation()

  const { isDesktop } = useMatchBreakpoints()
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body')


  return (
    <div>
      <BootstrapDialog
         sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '90%',
              maxWidth: isDesktop ? '730px' : '100%',
            },
          },
        }}
        open={open}
        scroll={scroll}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
          }}
        >
          <CloseIcon sx={{ color: '#FFFFFF' }} />
        </IconButton>
        <DialogContent sx={{ maxHeight: '60vh' }} dividers>
          <Box sx={{ p: 2 }}>
            {whiteList.map((address) => {
              return (
                <Box sx={{ mt: 1 }}>
                  <ItemAddress address={address}/>
                </Box>
              )
            })}
          </Box>
        </DialogContent>
        <Box display="flex" justifyContent="center" sx={{ pb: 5 }}>
          {isDesktop ? (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/Button.png")',
                backgroundAttachment: 'local',
                backgroundPosition: '50% 45%',
                width: 450,
                height: 50,
                textTransform: 'uppercase',
              }}
              loading={false}
              loadingPosition="start"
              loadingLeft='135px'
              onClick={handleClose}

            >
              <Box
                mr="10px"
                sx={{
                  mr: 2,
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {false ? t('Loading') : t('Done')}
              </Box>
            </CTButtonImage>
          ) : (<CTButtonImage
            sx={{
              backgroundImage: 'url("/images/button/ButtonMobile.png")',
              backgroundAttachment: 'local',
              backgroundPosition: '50% 45%',
              width: 210,
              height: 48,
              textTransform: 'uppercase',
            }}
            loading={false}
            loadingPosition="start"
            loadingLeft='20px'
            onClick={handleClose}

          >
            <Box
              mr="10px"
              sx={{
                mr: 2,
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                color: '#FFFFFF',
              }}
            >
              {false ? t('Loading') : t('Done')}
            </Box>
          </CTButtonImage>)}
        </Box>
      </BootstrapDialog>
    </div>
  )
}

export default DialogsWhiteList
