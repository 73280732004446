import { IconButton, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Unstable_Grid2'
import { useTranslation } from '@pancakeswap/localization'
import { useMatchBreakpoints, useToast } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import InfoToken from 'components/CustomizedDialogs/InfoToken'
import * as React from 'react'
import { useAppDispatch } from 'state'
import { toggleConnectWalletModal } from 'state/global/actions'
import { ThemeContext, styled as styledc } from 'styled-components'
import CTButtonImage from 'views/Component/CTButtonImage'
import CTButtonLearnMore from 'views/Component/CTButtonLearnMore'
import StepperLine from 'views/Component/StepperLine'
import HistoryTokenTable from 'views/History/HistoryTokenTable'
import { useAccount, useBalance, useNetwork, useWaitForTransaction, useWalletClient } from 'wagmi'
import DialogsCreateToken from '../../components/CustomizedDialogs/DialogsCreateToken'
import { Bytecode as ERC20TokenBytecode, Abi as ERC20TokenTokenAbi } from '../../constract/ERC20Token.json'
import { countDecimals } from '../../utils'
import { logApiToken } from '../../utils/logApi'
import CTTextField from '../Component/CTextField'
import IOSSwitch from '../Component/IOSSwithc'
import { InfoIcon } from '../Component/Icon/InfoIcon'

export const PageHr = styledc.hr`
  border: none;
  height: 1px;
  color: #4e4e4e;  /* old IE */
  background-color: #4e4e4e;  /* Modern Browsers */
`
export default function CoinTools() {

  
  // Modal connect wallet

  const dispatch = useAppDispatch()

  const showModal = () => {
    dispatch(toggleConnectWalletModal({ showModalWallet: true }))
  }



  const { isDesktop } = useMatchBreakpoints()
  const { t } = useTranslation()

  const theme = React.useContext(ThemeContext)

  // parameter contract
  const [name, setName] = React.useState('')
  const [symbol, setSymbol] = React.useState('')
  const [initialSupply, setInitialSupply] = React.useState<number>()
  const [decimals, setDecimals] = React.useState<number>(18)
  const [canBurn, setCanBurn] = React.useState(false)
  const [canMint, setCanMint] = React.useState(false)
  const [canPause, setCanPause] = React.useState(false)
  const [canBlacklist, setCanBlacklist] = React.useState(false)
  const [applyBrunFee, setApplyBrunFee] = React.useState(false)
  const [recipientAddress, setRecipientAddress] = React.useState('')
  const [txFee, setTxFee] = React.useState('')
  const [historyKey, setHistoryKey] = React.useState<number>(1)

  const { data, isError, isLoading } = useBalance({
    address: useAccount().address,
  })
  // submit
  const { data: walletClient } = useWalletClient()
  const { chain, chains } = useNetwork()
  const [hash, setHash] = React.useState<undefined | `0x${string}`>()
  const { toastSuccess, toastError } = useToast()
  const { address, isConnected } = useAccount()
  const {
    data: deployTx,
    isError: isErrorTx,
    isLoading: isLoandingTx,
  } = useWaitForTransaction({
    hash,
  })
  const [loading, setLoading] = React.useState(false)
  const [textButton, setTextButton] = React.useState(t('Create Token'))

  // Dialogs
  const [open, setOpen] = React.useState(false)

  // Step
  const [activeStep, setActiveStep] = React.useState(0)


  const stepNext = () => {
    if (activeStep === 0) {
      if (!name) {
        focusId('name')
        return toastError(t('Please input: Name'))
      }
      if (!symbol) {
        focusId('symbol')
        return toastError(t('Please input: Symbol'))
      }
      if (!initialSupply || initialSupply <= 0) {
        focusId('initialSupply')
        return toastError(t('Please input: Total supply.\n Note that the input value must be greater than 0'))
      }
      if (Number.isNaN(decimals) || decimals === undefined || decimals === null || decimals < 1 || decimals > 18) {
        focusId('decimals')
        return toastError(t('Decimals value must be between 1 and 18'))
      }
    }

    if (activeStep === 1) {
      if (applyBrunFee && !recipientAddress) {
        focusId('recipientAddress')
        return toastError(t('Please input: Recipient address'))
      }
      if (applyBrunFee && !txFee) {
        focusId('txFee')
        return toastError(t('Please input: Tax / fee'))
      }
      if (applyBrunFee && txFee && countDecimals(parseFloat(txFee)) > 1) {
        return toastError(t('The decimal value after the comma should have no more than one digit'))
      }
    }
    return setActiveStep(activeStep + 1);
  }

  const stepBack = () => {
    return setActiveStep(activeStep - 1);
  }


  const focusId = (id: string) => {
    document.getElementById(id).focus()
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClick = (v: boolean) => {
    setOpen(v)
  }
  const handleClose = () => {
    document.getElementById('historyToken').scrollIntoView();
    setOpen(false)
  }

  // clear form
  const [dataForm, setDataForm] = React.useState<any>()

  const clearForm = () => {
    setName('')
    setSymbol('')
    setInitialSupply(NaN)
    setDecimals(18)
    setCanBurn(false)
    setCanMint(false)
    setCanPause(false)
    setCanBlacklist(false)
    setApplyBrunFee(false)
    setRecipientAddress('')
    setTxFee('')
    setActiveStep(0)
  }

  React.useEffect(() => {
    if (address && hash && deployTx && deployTx.contractAddress) {
      logApiToken(address, name, symbol, initialSupply,  hash, deployTx.contractAddress)
      setLoading(false)
      handleClickOpen()
      clearForm()
      setTimeout(function() { setHistoryKey(historyKey + 1) }, 1000);
    }
  }, [deployTx, address, hash])


  const updateDataForm = () => {
    const dataF = {
      name,
      symbol,
      initialSupply,
      decimals,
      canBurn,
      canMint,
      canPause,
      canBlacklist,
      applyBrunFee,
      recipientAddress,
      txFee,
    }
    setDataForm(dataF)
  }
  React.useEffect(() => {
    if (activeStep > 1) { updateDataForm() }
  }, [activeStep])

  async function onSubmit() {
    const dataF = {
      name,
      symbol,
      initialSupply,
      decimals,
      canBurn,
      canMint,
      canPause,
      canBlacklist,
      applyBrunFee,
      recipientAddress,
      txFee,
    }

    setDataForm(dataF)

    if (!isConnected) {
      // if (refConnectWallet && refConnectWallet.current) {
      //   return refConnectWallet.current?.showConnectWallet();
      // }
      return showModal()
      // return toastError(t('Your wallet is not yet connected. To continue, please click on Connect Wallet button'))
    }
    if (!isConnected) {
      return toastError(t('Your wallet is not yet connected. To continue, please click on Connect Wallet button'))
    }
    if (parseFloat(data?.formatted) <= 0) {
      return toastError(t('Insufficient Balance'))
    }
    try {
      setLoading(true)
      let brunAddress = '0x0000000000000000000000000000000000000000'
      let brunFee = new BigNumber(0)
      if (applyBrunFee && recipientAddress) {
        brunAddress = recipientAddress
        brunFee = new BigNumber(txFee).multipliedBy(10)
        if (brunFee.isNaN()) brunFee = new BigNumber(0)
      }
      const response = await walletClient?.deployContract({
        abi: ERC20TokenTokenAbi,
        bytecode: ERC20TokenBytecode as `0x${string}`,
        args: [
          name,
          symbol,
          new BigNumber(initialSupply),
          new BigNumber(decimals),
          canBurn,
          canMint,
          canPause,
          canBlacklist,
          brunAddress,
          brunFee,
        ],
        chain,
      })
      return setHash(response)
    } catch (e: any) {
      setLoading(false)
      const sentences = (e.message as string).split('.')
      const mesage = (e.message as string).replace('Address', t('Address')).replace('is invalid', t('is invalid'))
      let firstSentence = sentences.shift()
      if (firstSentence === 'User rejected the request') {
        firstSentence = t('User rejected the request')
        return toastError(firstSentence)
      }
      return toastError(mesage)
    }
  }
  const steps = ['Basic setting', 'Token configuration', 'Create contract'];
  const refConnectWallet = React.useRef<any>(null)



  return (
    <>
      {/* <ConnectWallet ref={refConnectWallet}/> */}
      {open && (
        <DialogsCreateToken
          open={open}
          contract={address && hash && deployTx && deployTx.contractAddress ? deployTx.contractAddress : null}
          handleClose={handleClose}
          dataForm={dataForm}
        />
      )}
      <PageHr />
      <StepperLine sx={{ maxWidth: "1000px", margin: 'auto', mt: 5 }} steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />

      <Grid sx={{ display: (activeStep === 0) ? '' : 'none', mt: 5, background: '#111111', p: isDesktop ? 5 : 0, borderRadius: 3 }}>
        <Box pb="10px" textAlign='center'>
          <Box sx={{ fontSize: isDesktop ? '32px' : '24px', color: '#FFFFFF', fontWeight: 600, textTransform: 'capitalize' }}>
            {t('Basic setting')}
          </Box>
          <Box sx={{ mt: 1, fontSize: isDesktop ? '24px' : '16px', color: '#E1E1E1' }}>
            {t('Set up some basic information for your token')}
          </Box>
        </Box>
        <Grid sx={{ mt: 3 }}>
          <InputLabel
            sx={{
              color: '#D1D1D1',
              fontSize: isDesktop ? '20px' : '20px',
            }}
            shrink
            required
            htmlFor="name"
          >
            {t('Token Name')}
          </InputLabel>
          <CTTextField
            placeholder={t('e.g. Nice Token Name')}
            disabled={loading}
            size="medium"
            fullWidth
            id="name"
            InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <InputLabel
            sx={{
              color: '#D1D1D1',
              fontSize: isDesktop ? '20px' : '20px',
            }}
            shrink
            required
            htmlFor="name"
          >
            {t('Symbol')}
          </InputLabel>
          <CTTextField
            placeholder={`${t('e.g.')} NT`}
            disabled={loading}
            size="medium"
            fullWidth
            id="symbol"
            InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
            value={symbol}
            onChange={(event) => setSymbol(event.target.value)}
          />
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <InputLabel
            sx={{
              color: '#D1D1D1',
              fontSize: isDesktop ? '20px' : '20px',
            }}
            shrink
            required
            htmlFor="name"
          >
            {t('Total supply')}
          </InputLabel>
          <CTTextField
            disabled={loading}
            placeholder={t('e.g. 21,000,000')}
            size="medium"
            type="number"
            onWheel={(event: any) => event.target.blur()}
            fullWidth
            id="initialSupply"
            InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
            value={initialSupply}
            onChange={(event) => setInitialSupply(parseInt(event.target.value))}
          // onKeyDown={(event) => ['e', 'E', '+', '-', ',', '.'].includes(event.key) && event.preventDefault()}
          />
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <InputLabel
            sx={{
              color: '#D1D1D1',
              fontSize: isDesktop ? '20px' : '20px',
            }}
            shrink
            required
            htmlFor="name"
          >
            {t('Decimals (1-18)')}
          </InputLabel>
          <CTTextField
            disabled={loading}
            placeholder={t('e.g. 18')}
            size="medium"
            fullWidth
            id="decimals"
            type="number"
            onWheel={(event: any) => event.target.blur()}
            InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' }, inputProps: { min: 0, max: 10 } }}
            value={decimals}
            onChange={(event) => setDecimals(parseInt(event.target.value))}
          />
        </Grid>
        <Grid
          container
          alignItems={isDesktop ? 'center' : 'center'}
          justifyContent={isDesktop ? 'center' : 'center'}
          sx={{ pt: 5 }}
        >
          {isDesktop ? (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/Button.png")',
                backgroundPosition: '50% 45%',
                width: 450,
                height: 50,
                textTransform: 'uppercase',
              }}
              loading={loading}
              loadingPosition="start"
              loadingLeft="135px"
              onClick={() => { updateDataForm(); stepNext(); }}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Next') : t('Next')}
              </Box>
            </CTButtonImage>
          ) : (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/ButtonMobile.png")',
                backgroundPosition: '50% 45%',
                width: 210,
                height: 48,
                textTransform: 'uppercase',
              }}
              loading={loading}
              loadingPosition="start"
              loadingLeft="20px"
              onClick={() => { updateDataForm(); stepNext(); }}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Next') : t('Next')}
              </Box>
            </CTButtonImage>
          )}
        </Grid>
      </Grid>

      <Grid sx={{ display: (activeStep === 1) ? '' : 'none', mt: 5, background: '#111111',  p: isDesktop ? 5 : 0, borderRadius: 3 }}>
        <Box pb="10px" textAlign='center'>
          <Box sx={{ fontSize: isDesktop ? '32px' : '24px', color: '#FFFFFF', fontWeight: 600, textTransform: 'capitalize' }}>
            {t('Token configuration')}
          </Box>
          <Box sx={{ mt: 1, fontSize: isDesktop ? '24px' : '16px', color: '#E1E1E1' }}>
            {t('Set up advanced configuration, expands contract capbilities')}
          </Box>
        </Box>
        <Grid sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex' }}>
            <IOSSwitch
              disabled={loading}
              checked={canBurn}
              onChange={(event) => setCanBurn(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Box
              sx={{
                ml: 3,
                background: canBurn ? 'linear-gradient(180deg, #FFF3BF 0%, #E8CC93 45.83%, #BCA578 100%)' : '',
                '-webkit-background-clip': canBurn ? 'text' : '',
                '-webkit-text-fill-color': canBurn ? 'transparent' : '',
              }}
            >
              {t('Can Burn')}
              <Tooltip
                title={t('Enables token burning after initial token creation to decrease supply')}
                placement={isDesktop ? 'right' : 'bottom'}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex' }}>
            <IOSSwitch
              disabled={loading}
              checked={canMint}
              onChange={(event) => setCanMint(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Box
              sx={{
                display: 'inline',
                ml: 3,
                background: canMint ? 'linear-gradient(180deg, #FFF3BF 0%, #E8CC93 45.83%, #BCA578 100%)' : '',
                '-webkit-background-clip': canMint ? 'text' : '',
                '-webkit-text-fill-color': canMint ? 'transparent' : '',
              }}
            >
              {t('Can Mint')}
              <Tooltip
                title={t('Enables additional token minting after initial token creation to increase supply')}
                placement={isDesktop ? 'right' : 'bottom'}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex' }}>
            <IOSSwitch
              disabled={loading}
              checked={canPause}
              onChange={(event) => setCanPause(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Box
              sx={{
                display: 'inline',
                ml: 3,
                background: canPause ? 'linear-gradient(180deg, #FFF3BF 0%, #E8CC93 45.83%, #BCA578 100%)' : '',
                '-webkit-background-clip': canPause ? 'text' : '',
                '-webkit-text-fill-color': canPause ? 'transparent' : '',
              }}
            >
              {t('Can Pause')}
              <Tooltip
                title={t(
                  'Specifies whether your token and all associated operations can be halted and resumed whenever needed.',
                )}
                placement={isDesktop ? 'right' : 'bottom-end'}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex' }}>
            <IOSSwitch
              disabled={loading}
              checked={canBlacklist}
              onChange={(event) => setCanBlacklist(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Box
              sx={{
                display: 'inline',
                ml: 3,
                background: canBlacklist ? 'linear-gradient(180deg, #FFF3BF 0%, #E8CC93 45.83%, #BCA578 100%)' : '',
                '-webkit-background-clip': canBlacklist ? 'text' : '',
                '-webkit-text-fill-color': canBlacklist ? 'transparent' : '',
              }}
            >
              {t('Can Blacklist')}
              <Tooltip
                title={t(
                  'Specifies whether individual accounts such as malicious actors, hacker, cheater can be blacklisted after initial token creation',
                )}
                placement={isDesktop ? 'right' : 'bottom-end'}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex' }}>
            <IOSSwitch
              disabled={loading}
              checked={applyBrunFee}
              onChange={(event) => setApplyBrunFee(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Box
              sx={{
                display: 'inline',
                ml: 3,
                background: applyBrunFee ? 'linear-gradient(180deg, #FFF3BF 0%, #E8CC93 45.83%, #BCA578 100%)' : '',
                '-webkit-background-clip': applyBrunFee ? 'text' : '',
                '-webkit-text-fill-color': applyBrunFee ? 'transparent' : '',
              }}
            >
              {t('Apply Transaction Tax / Fee')}
              <Tooltip
                title={t(
                  'The specified portion of a token transfer will go to the specified tax / fee wallet. Be aware that enabling tax gives central authority to the token creator. It may make your token incompatible with some DeFi protocols. It cannot be deactivated after initial token creation',
                )}
                placement={isDesktop ? 'right' : 'bottom-end'}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        {applyBrunFee ? (
          <>
            <Grid sx={{ mt: 3 }}>
              <InputLabel
                sx={{
                  color: '#D1D1D1',
                  fontSize: isDesktop ? '16px' : '16px',
                }}
                shrink
                htmlFor="name"
                required
              >
                {t('Recipient address')}
              </InputLabel>
              <CTTextField
                disabled={loading}
                size="medium"
                placeholder={`${t('e.g.')} 0x924897DC867F06a1e3c5579bB0B75Df3025D5e9D`}
                fullWidth
                id="recipientAddress"
                InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
                value={recipientAddress}
                onChange={(event) => setRecipientAddress(event.target.value)}
              />
              <Box sx={{ display: 'block', mt: 1, fontSize: '0.8rem', color: '#9E9E9E' }}>
                {t('Can be updated after initial token creation.')}
              </Box>
            </Grid>
            <Grid sx={{ mt: 3 }}>
              <InputLabel
                sx={{
                  color: '#D1D1D1',
                  fontSize: isDesktop ? '16px' : '16px',
                }}
                shrink
                htmlFor="name"
                required
              >
                {t('Tax / fee on transfer in basis points (bps)')}
              </InputLabel>
              <CTTextField
                disabled={loading}
                placeholder={t('e.g. 100')}
                size="medium"
                fullWidth
                id="txFee"
                value={txFee}
                type="number"
                onWheel={(event: any) => event.target.blur()}
                InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
                onChange={(event) => setTxFee(event.target.value.toString())}
              />
              <Box sx={{ display: 'block', mt: 1, fontSize: '0.8rem', color: '#9E9E9E' }}>
                {t(
                  'Specify the tax / fee in basis points (bps), i.e. 1% is equal to 100 bps. Example: to charge a tax / fee of 3.5%, enter the number 350. Can be updated after initial token creation.',
                )}
              </Box>
            </Grid>
          </>
        ) : null}
        <Grid
          container
          alignItems={isDesktop ? 'center' : 'center'}
          justifyContent={isDesktop ? 'center' : 'center'}
          sx={{ pt: 5 , flexWrap: 'nowrap' }}
        >
            {isDesktop ? (
              <CTButtonImage
                startIcon={<img src="/images/button/back.svg" alt="" />}
                onClick={() => { stepBack(); }}
              >
                <Box
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                  }}
                >
                  {loading ? t('Back') : t('Back')}
                </Box>
              </CTButtonImage>
            ) : (
              <CTButtonLearnMore
              className="show"
              sx={{
                width: '100px',
                height: '48px',
                mr: 3,
              }}
              onClick={() => {
                stepBack()
              }}
              variant="contained"
              disableRipple
            >
              {t('Back')}
            </CTButtonLearnMore>
            )}
            {isDesktop ? (
              <Box sx={{flexGrow: 1, textAlign:'center'}}>
              <CTButtonImage
                sx={{
                  backgroundImage: 'url("/images/button/Button.png")',
                  backgroundPosition: '50% 45%',
                  width: 450,
                  height: 50,
                }}
                loading={loading}
                loadingPosition="start"
                loadingLeft="135px"
                onClick={() => { stepNext(); }}
              >
                <Box
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                  }}
                >
                  {loading ? t('Next') : t('Next')}
                </Box>
              </CTButtonImage>
              </Box>
            ) : (
              <CTButtonImage
                sx={{
                  backgroundImage: 'url("/images/button/ButtonMobile.png")',
                  backgroundPosition: '50% 45%',
                  width: 210,
                  height: 48,
                  textTransform: 'uppercase',
                }}
                loading={loading}
                loadingPosition="start"
                loadingLeft="20px"
                onClick={() => { stepNext(); }}
              >
                <Box
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                  }}
                >
                  {loading ? t('Next') : t('Next')}
                </Box>
              </CTButtonImage>
            )}
        </Grid>
      </Grid>

      <Grid sx={{ display: (activeStep === 2) ? '' : 'none', mt: 5, background: '#111111',  p: isDesktop ? 5 : 0, borderRadius: 3 }}>
        <Box pb="10px" textAlign='center'>
          <Box sx={{ fontSize: isDesktop ? '32px' : '24px', color: '#FFFFFF',  fontWeight: 600, textTransform: 'capitalize'  }}>
            {t('Create contract')}
          </Box>
          <Box sx={{ mt: 1, fontSize: isDesktop ? '24px' : '16px', color: '#E1E1E1' }}>
            {t('Please ensure the following details are correct')}
          </Box>
        </Box>
        <InfoToken open={open}
          contract={address && hash && deployTx && deployTx.contractAddress ? deployTx.contractAddress : null}
          handleClose={handleClose}
          dataForm={dataForm} />
        <Grid
          container
          alignItems={isDesktop ? 'center' : 'center'}
          justifyContent={isDesktop ? 'center' : 'center'}
          sx={{ pt: 5 }}
        >

                      {isDesktop ? (
              <CTButtonImage
                startIcon={<img src="/images/button/back.svg" alt="" />}
                onClick={() => { stepBack(); }}
              >
                <Box
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                  }}
                >
                  {loading ? t('Back') : t('Back')}
                </Box>
              </CTButtonImage>
            ) : (
              <CTButtonLearnMore
              className="show"
              sx={{
                width: '100px',
                height: '48px',
                mr: 3,

              }}
              onClick={() => {
                stepBack()
              }}
              variant="contained"
              disableRipple
            >
              {t('Back')}
            </CTButtonLearnMore>
            )}

          {isDesktop ? (
          <Box sx={{flexGrow: 1, textAlign:'center'}}>
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/Button.png")',
                backgroundPosition: '50% 45%',
                width: 450,
                height: 50,
                textTransform: 'uppercase',
              }}
              loading={loading}
              loadingLeft="-60px"
              onClick={onSubmit}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Creating') : t('Create Token')}
              </Box>
            </CTButtonImage>
            </Box>
          ) : (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/ButtonMobile.png")',
                backgroundPosition: '50% 45%',
                width: 210,
                height: 48,
                textTransform: 'uppercase',
              }}
              loading={loading}
              loadingPosition="start"
              loadingLeft="20px"
              onClick={onSubmit}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Creating') : t('Create Token')}
              </Box>
            </CTButtonImage>
          )}
        </Grid>
      </Grid>
      <Grid sx={{ mt: 5}}>
      <HistoryTokenTable  key={historyKey}/>
      </Grid>
    </>
  )
}
