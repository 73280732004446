export const LinkIcon = ({ ...props }) => {
  return (
    <svg
      width={props.width ? props.width : '30'}
      height={props.height ? props.height : '30'}
      viewBox="0 0 30 30"
      fill={props.fill ? props.fill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.81875 13.7497C4.06267 11.5756 4.93461 9.51957 6.32801 7.83292C7.72142 6.14627 9.57595 4.90202 11.665 4.25221C9.96512 7.14465 8.97832 10.4002 8.78625 13.7497H3.81875ZM13.74 1.30721C6.7375 1.94221 1.25 7.83096 1.25 14.9997C1.25 22.1685 6.7375 28.056 13.74 28.6922L13.75 28.7047L14.2812 28.731C14.5207 28.7427 14.7603 28.749 15 28.7497C15.2397 28.7496 15.4794 28.7433 15.7188 28.731L16.25 28.706L16.26 28.6935C23.2625 28.0547 28.75 22.1685 28.75 14.9997C28.75 7.83096 23.2625 1.94221 16.26 1.30721L16.25 1.29471L15.7175 1.26846C15.2395 1.24385 14.7605 1.24385 14.2825 1.26846L13.75 1.29346L13.74 1.30596V1.30721ZM15.0025 3.74971C17.1851 6.65116 18.4732 10.1266 18.7087 13.7497H11.2913C11.5272 10.1265 12.8157 6.65101 14.9987 3.74971H15H15.0025ZM8.785 16.2497C8.985 19.6997 10.01 22.931 11.6638 25.7472C9.5747 25.0974 7.72017 23.8532 6.32677 22.1665C4.93336 20.4799 4.06142 18.4238 3.8175 16.2497H8.785ZM14.9975 26.2497C12.8145 23.3484 11.5259 19.8729 11.29 16.2497H18.7075C18.4716 19.8729 17.183 23.3484 15 26.2497H14.9975ZM18.335 25.7472C20.0344 22.8546 21.0208 19.5991 21.2125 16.2497H26.18C25.9361 18.4238 25.0641 20.4799 23.6707 22.1665C22.2773 23.8532 20.4228 25.0974 18.3337 25.7472H18.335ZM21.2125 13.7497C21.0125 10.2997 19.9875 7.06846 18.3337 4.25221C20.423 4.90181 22.2778 6.14598 23.6715 7.83264C25.0651 9.51931 25.9372 11.5754 26.1812 13.7497H21.2138H21.2125Z"
        fill="url(#paint0_linear_1861_1749)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1861_1749"
          x1="15.3449"
          y1="1.25"
          x2="15.3449"
          y2="28.7497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF3BF" />
          <stop offset="0.458333" stopColor="#E8CC93" />
          <stop offset="1" stopColor="#BCA578" />
        </linearGradient>
      </defs>
    </svg>
  )
}
