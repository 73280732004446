import { Box, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import { CopyIcon } from '../../views/Component/Icon/CopyIcon'



const ItemAddress = ({ address }: { address: string }) => {
  const [openTip, setOpenTip] = React.useState(false)

  const handleTooltipClose = () => {
    setOpenTip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTip(true)
    setTimeout(()=> {
      setOpenTip(false)
    }, 1000)
  }

  const copyAddressContract = (t: string) => {
    navigator.clipboard.writeText(t)
    handleTooltipOpen()
  }
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        pt: 1,
        border: '1px solid rgba(36, 36, 36, 1)',
        borderRadius: '12px',
        background: `linear-gradient(0deg, #0A0A0A, #0A0A0A), linear-gradient(0deg, #242424, #242424)`,
        color: '#FFFFFF',
      }}
    >
      <Box
        sx={{
          p: 2,
          mt: -1,
          ml: 1,
          mr: 5,
          display: 'block',
          wordBreak: 'break-all',
          textAlign: 'left',
          fontSize: '18px',
        }}
        justifyContent="left"
      >
        {address}
      </Box>
      <Box justifyContent="right" sx={{ mr: 2, display: 'block' }}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={openTip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied"
        >
          <IconButton
            onClick={() => {
              copyAddressContract(address)
            }}
          >
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default ItemAddress
