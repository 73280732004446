import { Button, IconButton, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'
import { useTranslation } from '@pancakeswap/localization'
import { useMatchBreakpoints, useToast } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import ConnectWallet from 'components/ConnectWallet'
import InfoNFT from 'components/CustomizedDialogs/InfoNFT'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext, styled as styledc } from 'styled-components'
import CTButtonLearnMore from 'views/Component/CTButtonLearnMore'
import StepperLine from 'views/Component/StepperLine'
import HistoryNftTable from 'views/History/HistoryNftTable'
import { useAccount, useBalance, useNetwork, useWaitForTransaction, useWalletClient } from 'wagmi'
import DialogsCreateNFT from '../../components/CustomizedDialogs/DialogsCreateNFT'
import DialogsWhiteList from '../../components/CustomizedDialogs/DialogsWhiteList'
import { Abi as ERC721NFTAbi, Bytecode as ERC721NFTBytecode } from '../../constract/ERC721NFT.json'
import { logApiNft } from '../../utils/logApi'
import { isValidHttpUrl } from '../../validation'
import CTButtonImage from '../Component/CTButtonImage'
import CTTextField from '../Component/CTextField'
import IOSSwitch from '../Component/IOSSwithc'
import { InfoIcon } from '../Component/Icon/InfoIcon'
import { LinkIcon } from '../Component/Icon/LinkIcon'

export const PageHr = styledc.hr`
  border: none;
  height: 1px;
  color: #4e4e4e;  /* old IE */
  background-color: #4e4e4e;  /* Modern Browsers */
`

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const BootstrapButton: FC<any> = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: 'none',
  borderColor: 'none',
  boder: '0px',
  borderWidth: '0px',
  '&.MuiButtonBase-root': {
    color: '#FFF3BF',
    'text-decoration': 'underline',
  },
  '&:hover': {
    backgroundColor: 'none',
    borderColor: 'none',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'none',
    borderColor: 'none',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
})

export default function CreateNFT() {
  const { isDesktop } = useMatchBreakpoints()
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  // parameter contract
  const [name, setName] = useState('')
  const [symbol, setSymbol] = useState('')
  const [collectionSize, setCollectionSize] = useState<number>()
  const [metaLink, setMetaLink] = useState('')
  const [enablePublicMint, setEnablePublicMint] = useState(false)
  const [enableWhiteList, setEnableWhiteList] = useState(false)
  const [mintPrice, setMintPrice] = useState('0')
  const [whiteList, setWhiteList] = useState('')
  const { address, isConnected } = useAccount()
  const { data, isError, isLoading } = useBalance({
    address: useAccount().address,
  })
  // submit
  const { data: walletClient } = useWalletClient()
  const { chain, chains } = useNetwork()
  const [hash, setHash] = useState<undefined | `0x${string}`>()
  const { toastSuccess, toastError } = useToast()
  const {
    data: deployTx,
    isError: isErrorTx,
    isLoading: isLoandingTx,
  } = useWaitForTransaction({
    hash,
  })
  const [loading, setLoading] = useState(false)
  const [textButton, setTextButton] = useState(t('Create'))

  const [dataForm, setDataForm] = useState<any>()
  const steps = ['Basic setting', 'Advanced settings', 'Create contract']
  const [activeStep, setActiveStep] = useState(0)

  const refConnectWallet = useRef<any>(null)
  const [historyKey, setHistoryKey] = useState<number>(1)

  const clearForm = () => {
    setName('')
    setSymbol('')
    setCollectionSize(NaN)
    setMetaLink('')
    setEnablePublicMint(false)
    setEnableWhiteList(false)
    setMintPrice('0')
    setWhiteList('')
    setActiveStep(0)
  }

  useEffect(() => {
    if (address && hash && deployTx && deployTx.contractAddress) {
      logApiNft(address, name, symbol, collectionSize, metaLink,  hash, deployTx.contractAddress)
      setLoading(false)
      handleClickOpen()
      clearForm()
      setTimeout(function(){setHistoryKey(historyKey + 1)}, 1000);
    }
  }, [deployTx])

  const focusId = (id: string) => {
    document.getElementById(id).focus()
  }

  const getWhiteList = () => {
    let wList = []
    if (enableWhiteList && whiteList) {
      wList = whiteList
        .trim()
        .replace(/(\r\n|\n|\r)/gm, ',')
        .split(',')
    }
    return wList
  }

  const getWhiteListForm = () => {
    let wList = []
    if (enableWhiteList && whiteList) {
      wList = dataForm?.whiteList
        .trim()
        .replace(/(\r\n|\n|\r)/gm, ',')
        .split(',')
    }
    return wList
  }

  async function onSubmit() {
    const dataF = {
      name,
      symbol,
      collectionSize,
      metaLink,
      enablePublicMint,
      enableWhiteList,
      mintPrice,
      whiteList,
    }
    setDataForm(dataF)
    if (!isConnected) {
      if (refConnectWallet && refConnectWallet.current) {
        return refConnectWallet.current?.showConnectWallet()
      }
      return toastError('Your wallet is not yet connected. To continue, please click on Connect Wallet button')
    }
    if (parseFloat(data?.formatted) <= 0) {
      return toastError(t('Insufficient Balance'))
    }
    let wList = []
    if (enableWhiteList && whiteList) {
      wList = getWhiteList()
    }
    try {
      setLoading(true)
      let mPrice = new BigNumber(0)
      if (enablePublicMint) {
        mPrice = new BigNumber(mintPrice).multipliedBy(10 ** 18)
        if (mPrice.isNaN()) mPrice = new BigNumber(0)
      }
      const response = await walletClient?.deployContract({
        abi: ERC721NFTAbi,
        bytecode: ERC721NFTBytecode as `0x${string}`,
        args: [name, symbol, metaLink, new BigNumber(collectionSize), enablePublicMint, mPrice, wList],
        chain,
      })
      return setHash(response)
    } catch (e: any) {
      setLoading(false)
      const mesage = (e.message as string).replace('Address', t('Address')).replace('is invalid', t('is invalid'))
      const sentences = (e.message as string).split('.')
      let firstSentence = sentences.shift()
      if (firstSentence === 'User rejected the request') {
        firstSentence = t('User rejected the request')
        return toastError(firstSentence)
      }
      return toastError(mesage)
    }
  }

  function resetEvent(event) {
    event.target.value = '' // eslint-disable-line no-param-reassign
  }

  const handleFileChange = (event) => {
    const input = event.target.files[0]
    const reader = new FileReader()
    reader.onload = function (e) {
      const text = e.target.result as string
      setWhiteList(text)
    }
    reader.readAsText(input)
    resetEvent(event)
  }

  const goLink = (link: string) => {
    window.open(link)
  }

  const goMetaLink = () => {
    if (!metaLink || !isValidHttpUrl(metaLink)) {
      document.getElementById('metaLink').focus()
      toastError(t('Metadata Link is not in the valid format'))
    } else {
      window.open(metaLink)
    }
  }

  // Dialogs

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClick = (v: boolean) => {
    setOpen(v)
  }
  const handleClose = () => {
    document.getElementById('historyNft').scrollIntoView();
    setOpen(false)
  }
  const [openDialogWhiteList, setOpenDialogWhiteList] = useState(false)

  const handleCloseDialogWhiteList = () => {
    setOpenDialogWhiteList(false)
  }

  const handleOpenDialogWhiteList = () => {
    setOpenDialogWhiteList(true)
  }
  const stepNext = () => {
    if (activeStep === 0) {
      if (!name) {
        focusId('name')
        return toastError(t('Please input: Name'))
      }
      if (!symbol) {
        focusId('symbol')
        return toastError(t('Please input: Symbol'))
      }
      if (!collectionSize || collectionSize <= 0) {
        focusId('collectionSize')
        return toastError(
          t('Please input: Overall collection size.\n Note that the input value must be greater than 0'),
        )
      }
      if (!metaLink || !isValidHttpUrl(metaLink)) {
        focusId('metaLink')
        return toastError(t('Metadata Link is not in the valid format'))
      }
    }
    if (activeStep === 1) {
      if (enablePublicMint && !mintPrice) {
        focusId('mintPrice')
        return toastError(t('Please input: Mint price'))
      }
      if (enablePublicMint && parseFloat(mintPrice) < 0) {
        focusId('mintPrice')
        return toastError(
          t('Please input: Overall Mint price.\n Note that the input value must be greater than or equal to 0'),
        )
      }
      if (enableWhiteList && !whiteList) {
        focusId('whiteList')
        return toastError(t('Please input: Address List'))
      }
    }
    return setActiveStep(activeStep + 1)
  }

  const stepBack = () => {
    return setActiveStep(activeStep - 1)
  }

  const updateDataForm = () => {
    const dataF = {
      name,
      symbol,
      collectionSize,
      metaLink,
      enablePublicMint,
      enableWhiteList,
      mintPrice,
      whiteList,
    }
    setDataForm(dataF)
  }

  return (
    <>
      <ConnectWallet ref={refConnectWallet} />
      {openDialogWhiteList && (
        <DialogsWhiteList
          open={openDialogWhiteList}
          whiteList={getWhiteListForm()}
          handleClose={handleCloseDialogWhiteList}
        />
      )}
      {open && (
        <DialogsCreateNFT
          handleOpenDialogWhiteList={handleOpenDialogWhiteList}
          open={open}
          contract={address && hash && deployTx && deployTx.contractAddress ? deployTx.contractAddress : null}
          handleClose={handleClose}
          dataForm={dataForm}
        />
      )}

      <PageHr />

      <StepperLine
        sx={{ maxWidth: '1000px', margin: 'auto', mt: 5 }}
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />

      <Grid sx={{ display: activeStep === 0 ? 'block' : 'none', mt: 5, background: '#111111',  p: isDesktop ? 5 : 0, borderRadius: 3 }}>
        <Box pb="10px" textAlign="center">
          <Box sx={{ fontSize: isDesktop ? '32px' : '24px', color: '#FFFFFF', fontWeight: 600, textTransform: 'capitalize'}}>
            {t('Basic setting')}
          </Box>
          <Box sx={{ mt: 1, fontSize: isDesktop ? '24px' : '16px', color: '#E1E1E1' }}>
            {t('Set up some basic information for your NFTs')}
          </Box>
        </Box>
        <Grid sx={{ mt: 3 }}>
          <InputLabel
            sx={{
              color: '#D1D1D1',
              fontSize: isDesktop ? '20px' : '20px',
            }}
            shrink
            htmlFor="name"
            required
          >
            {t('Name')}
          </InputLabel>
          <CTTextField
            disabled={loading}
            size="medium"
            placeholder={t('Enter NFT Name')}
            fullWidth
            id="name"
            InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <InputLabel
            sx={{
              color: '#D1D1D1',
              fontSize: isDesktop ? '20px' : '20px',
            }}
            shrink
            htmlFor="name"
            required
          >
            {t('Symbol')}
          </InputLabel>
          <CTTextField
            disabled={loading}
            placeholder={t('Enter NFT Symbol')}
            size="medium"
            fullWidth
            id="symbol"
            InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
            value={symbol}
            onChange={(event) => setSymbol(event.target.value)}
          />
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <InputLabel
            sx={{
              color: '#D1D1D1',
              fontSize: isDesktop ? '20px' : '20px',
            }}
            shrink
            htmlFor="name"
            required
          >
            {t('Overall Collection Size')}
          </InputLabel>
          <CTTextField
            disabled={loading}
            placeholder={t('Enter total of NFT can be mint')}
            size="medium"
            fullWidth
            type="number"
            onWheel={(event: any) => event.target.blur()}
            id="collectionSize"
            InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
            value={collectionSize}
            onChange={(event) => setCollectionSize(parseInt(event.target.value))}
          // onKeyDown={(event) => ['e', 'E', '+', '-', ',', '.'].includes(event.key) && event.preventDefault()}
          />
        </Grid>

        <Grid sx={{ mt: 3 }}>
          <InputLabel
            sx={{
              color: '#D1D1D1',
              fontSize: isDesktop ? '20px' : '20px',
            }}
            shrink
            htmlFor="name"
            required
          >
            {t('Metadata Link')}
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
            }}
          >
            <CTTextField
              disabled={loading}
              placeholder={t('Enter your NFT Metadata, using .json file format')}
              fullWidth
              size="medium"
              id="metaLink"
              value={metaLink}
              onChange={(event) => setMetaLink(event.target.value)}
              InputProps={{ sx: { border: 'solid #e0e0e0ee 0px ', pr: 4, borderRadius: 3, color: '#9E9E9E' } }}
            />
            <IconButton
              onClick={goMetaLink}
              sx={{ position: 'absolute', right: '1px', display: 'absolute', overflow: 'visible', mt: 0.5 }}
            >
              <LinkIcon width="30" height="30" />
            </IconButton>
          </Box>
        </Grid>
        <Grid
          container
          alignItems={isDesktop ? 'center' : 'center'}
          justifyContent={isDesktop ? 'center' : 'center'}
          sx={{ pt: 5 }}
        >
          {isDesktop ? (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/Button.png")',
                backgroundPosition: '50% 45%',
                width: 450,
                height: 50,
                textTransform: 'uppercase',
              }}
              loading={loading}
              loadingPosition="start"
              loadingLeft="135px"
              onClick={() => {
                stepNext()
              }}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Next') : t('Next')}
              </Box>
            </CTButtonImage>
          ) : (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/ButtonMobile.png")',
                backgroundPosition: '50% 45%',
                width: 210,
                height: 48,
                textTransform: 'uppercase',
              }}
              loading={loading}
              loadingPosition="start"
              loadingLeft="20px"
              onClick={() => {
                stepNext()
              }}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Next') : t('Next')}
              </Box>
            </CTButtonImage>
          )}
        </Grid>
      </Grid>

      <Grid sx={{ display: activeStep === 1 ? 'block' : 'none', mt: 5, background: '#111111',  p: isDesktop ? 5 : 0, borderRadius: 3 }}>
        <Box pb="10px" textAlign="center">
          <Box sx={{ fontSize: isDesktop ? '32px' : '24px', color: '#FFFFFF', fontWeight: 600, textTransform: 'capitalize' }}>
            {t('Advanced settings')}
          </Box>
          <Box sx={{ mt: 1, fontSize: isDesktop ? '24px' : '16px', color: '#E1E1E1' }}>
            {t('Set up advanced configuration, expands contract capbilities')}
          </Box>
        </Box>
        <Grid sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex' }}>
            <IOSSwitch
              disabled={loading}
              checked={enablePublicMint}
              onChange={(event) => setEnablePublicMint(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Box sx={{ display: 'inline', ml: 3, color: enablePublicMint ? theme.colors.primary : '#E0E0E0' }}>
              {t('Enable Public Minting')}
              <Tooltip
                title={t('Enable to allow minting of this NFT for everybody')}
                placement={isDesktop ? 'right' : 'bottom-end'}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        {enablePublicMint ? (
          <>
            <Grid sx={{ mt: 3 }}>
              <InputLabel
                sx={{
                  color: '#D1D1D1',
                }}
                shrink
                htmlFor="name"
              >
                {t('Mint price*')}
              </InputLabel>
              <CTTextField
                disabled={loading}
                size="medium"
                placeholder={t('e.g. 0.001')}
                fullWidth
                id="mintPrice"
                type="number"
                onWheel={(event: any) => event.target.blur()}
                InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
                value={mintPrice}
                onChange={(event) => setMintPrice(event.target.value.toString())}
              />
              <Box sx={{ display: 'block', mt: 1, fontSize: '0.8rem', color: '#9E9E9E' }}>
                {t(
                  "This is the price to mint one NFT. If the price is set to 0, minting is free. Fees are transferred directly to the creator's wallet",
                )}
              </Box>
            </Grid>
          </>
        ) : null}
        <Grid sx={{ mt: 3 }}>
          <InputLabel
            sx={{
              color: enableWhiteList ? theme.colors.primary : '#E0E0E0',
            }}
            shrink
            htmlFor="name"
          >
            {t('White List')}
          </InputLabel>
          <IOSSwitch
            disabled={loading}
            checked={enableWhiteList}
            onChange={(event) => setEnableWhiteList(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>

        {enableWhiteList ? (
          <>
            <Grid sx={{ mt: 3 }}>
              <InputLabel
                sx={{
                  display: 'inline',
                  color: '#D1D1D1',
                }}
                shrink
                htmlFor="name"
              >
                {t('Address List')}
              </InputLabel>
              <Box sx={{ display: 'inline', float: 'right' }}>
                <BootstrapButton
                  component="label"
                  sx={{ color: '#D1D1D1' }}
                  onClick={() => {
                    goLink(`${process.env.NEXT_PUBLIC_BASE_URL}/file/address-list-example.csv`)
                  }}
                  variant="text"
                >
                  {t('File example')}
                </BootstrapButton>
                <BootstrapButton component="label" sx={{ color: '#D1D1D1' }} variant="text">
                  {t('Upload File')}
                  <VisuallyHiddenInput accept=".csv, .txt" type="file" onChange={handleFileChange} />
                </BootstrapButton>
              </Box>
            </Grid>
            <Grid sx={{ mt: 3 }}>
              <CTTextField
                disabled={loading}
                fullWidth
                id="whiteList"
                placeholder={t('Enter a list of addresses, go to the next line after each wallet')}
                multiline
                InputProps={{ sx: { borderRadius: 3, color: '#9E9E9E' } }}
                value={whiteList}
                onChange={(event) => setWhiteList(event.target.value)}
              />
            </Grid>
          </>
        ) : null}
        <Grid
          container
          alignItems={isDesktop ? 'center' : 'center'}
          justifyContent={isDesktop ? 'center' : 'center'}
          sx={{ pt: 5, flexWrap: 'nowrap' }}
        >
          {isDesktop ? (
            <CTButtonImage
              startIcon={<img src="/images/button/back.svg" alt="" />}
              onClick={() => {
                stepBack()
              }}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Back') : t('Back')}
              </Box>
            </CTButtonImage>
          ) : (
            <CTButtonLearnMore
            className="show"
            sx={{
              width: '100px',
              height: '48px',
              mr: 3,

            }}
            onClick={() => {
              stepBack()
            }}
            variant="contained"
            disableRipple
          >
            {t('Back')}
          </CTButtonLearnMore>
          )}
          {isDesktop ? (
          <Box sx={{flexGrow: 1, textAlign:'center'}}>
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/Button.png")',
                backgroundPosition: '50% 45%',
                width: 450,
                height: 50,
              }}
              loading={loading}
              loadingPosition="start"
              loadingLeft="135px"
              onClick={() => {
                updateDataForm()
                stepNext()
              }}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Next') : t('Next')}
              </Box>
            </CTButtonImage>
            </Box>
          ) : (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/ButtonMobile.png")',
                backgroundPosition: '50% 45%',
                width: 210,
                height: 48,
                textTransform: 'uppercase',
              }}
              loading={loading}
              loadingPosition="start"
              loadingLeft="20px"
              onClick={() => {
                updateDataForm()
                stepNext()
              }}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Next') : t('Next')}
              </Box>
            </CTButtonImage>
          )}
        </Grid>
      </Grid>
      <Grid sx={{ display: activeStep === 2 ? 'block' : 'none', mt: 5, background: '#111111',  p: isDesktop ? 5 : 0, borderRadius: 3 }}>
        <Box pb="10px" textAlign="center">
          <Box sx={{ fontSize: isDesktop ? '32px' : '24px', color: '#FFFFFF', fontWeight: 600, textTransform: 'capitalize'  }}>
            {t('Create contract')}
          </Box>
          <Box sx={{ mt: 1, fontSize: isDesktop ? '24px' : '16px', color: '#E1E1E1' }}>
            {t('Please ensure the following details are correct')}
          </Box>
        </Box>
        <InfoNFT
          handleOpenDialogWhiteList={handleOpenDialogWhiteList}
          open={open}
          contract={address && hash && deployTx && deployTx.contractAddress ? deployTx.contractAddress : null}
          handleClose={handleClose}
          dataForm={dataForm}
        />

        <Grid
          container
          alignItems={isDesktop ? 'center' : 'center'}
          justifyContent={isDesktop ? 'center' : 'center'}
          sx={{ pt: 5 }}
        >
          {isDesktop ? (
            <CTButtonImage
              startIcon={<img src="/images/button/back.svg" alt="" />}
              onClick={() => {
                stepBack()
              }}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Back') : t('Back')}
              </Box>
            </CTButtonImage>
          ) : (
            <CTButtonLearnMore
            className="show"
            sx={{
              width: '100px',
              height: '48px',
              mr: 3,

            }}
            onClick={() => {
              stepBack()
            }}
            variant="contained"
            disableRipple
          >
            {t('Back')}
          </CTButtonLearnMore>
          )}
          {isDesktop ? (
            <Box sx={{flexGrow: 1, textAlign:'center'}}>
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/Button.png")',
                backgroundPosition: '50% 45%',
                width: 450,
                height: 50,
                textTransform: 'uppercase',
              }}
              loading={loading}
              loadingPosition="center"
              loadingLeft="-100px"
              onClick={onSubmit}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Deploy Contract') : t('Deploy Contract')}
              </Box>
            </CTButtonImage>
            </Box>
          ) : (
            <CTButtonImage
              sx={{
                backgroundImage: 'url("/images/button/ButtonMobile.png")',
                backgroundPosition: '50% 45%',
                width: 210,
                height: 48,
                textTransform: 'uppercase',
              }}
              loading={loading}
              loadingPosition="start"
              loadingLeft="0px"
              onClick={onSubmit}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
              >
                {loading ? t('Deploy Contract') : t('Deploy Contract')}
              </Box>
            </CTButtonImage>
          )}
        </Grid>
      </Grid>
      <Grid sx={{ mt: 5}}>
      <HistoryNftTable key={historyKey}/>
      </Grid>
    </>
  )
}
