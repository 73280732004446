import { Box } from '@mui/material'
import { useTranslation } from '@pancakeswap/localization'
import { useRouter } from 'next/router'
import { useAppDispatch } from 'state'
import { useTabLink } from 'state/tab/hooks'
import { setTabLink } from 'state/tab/tabLink'
import { styled } from 'styled-components'
import CTButtonImage from 'views/Component/CTButtonImage'
import Hero from 'views/Home/components/Hero'
import { PageSection, useMatchBreakpoints, useModal } from '../../../../../packages/uikit/src'
import { IdType } from '../../hooks/useUserIsUsCitizenAcknowledgement'
import CoinTools from '../../views/CoinTools'
import CreateNFT from '../../views/CreateNFT'
import { useMenuItems } from '../Menu/hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from '../Menu/utils'
import USCitizenConfirmModal from '../Modal/USCitizenConfirmModal'

export const PageHr = styled.hr`
  border: none;
  height: 1px;
  color: #4e4e4e; /* old IE */
  background-color: #4e4e4e; /* Modern Browsers */
`

export const CoinToolLayout = () => {
  const { t } = useTranslation()
  const [onUSCitizenModalPresent] = useModal(
    <USCitizenConfirmModal title={t('PancakeSwap Perpetuals')} id={IdType.PERPETUALS} />,
    false,
    false,
    'usCitizenConfirmModal',
  )
  const { pathname } = useRouter()
  const menuItems = useMenuItems(onUSCitizenModalPresent)
  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
  const { isDesktop } = useMatchBreakpoints()

  const tabLink = useTabLink()
  const dispatch = useAppDispatch()


  const getImageItemMenu = (href: string, active: boolean) => {
    if (active) {
      switch (href) {
        case '/':
          return 'CreateTokenIconActive.png'
          break
        case '/create-nft':
          return 'CreateNFTIconActive.png'
          break
        case '/airdrop':
          return 'CreateTokenIconActive.png'
          break
        case '/pools-staking':
          return 'CreateTokenIconActive.png'
          break
        default:
          return 'CreateTokenIconActive.png'
      }
    } else {
      switch (href) {
        case '/':
          return 'CreateTokenIcon.png'
          break
        case '/create-nft':
          return 'CreateNFTIcon.png'
          break
        case '/airdrop':
          return 'CreateTokenIcon.png'
        case '/pools-staking':
          return 'CreateTokenIcon.png'
          break
        default:
          return 'CreateTokenIcon.png'
      }
    }
  }

  const StyledHeroSection = styled(PageSection)`
    padding-top: 0px;
    background: black;

    ${({ theme }) => theme.mediaQueries.md} {
      padding-top: 0px;
    }
  `
  return (
    <>
    <Box sx={{overflowX: "hidden"}}>
      <StyledHeroSection
        innerProps={{ style: { marginTop: '3rem', width: '100%'} }}
        containerProps={{
          id: 'home-1',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <Hero />
      </StyledHeroSection>
      <Box
        sx={{ background: '#000000', marginTop: !isDesktop? "50px" : "0px" }}
        display="flex"
        justifyContent="center"
        id="tab"
      >
        {isDesktop && (
          <Box width="1390px" display="flex" mt="-100px" justifyContent="center" maxWidth="100%">
            {menuItems[3].items.map((item) => (
              <>
                {activeSubMenuItem && activeSubMenuItem.href && item.href === tabLink ? (
                  <CTButtonImage
                    startIcon={<img src={`/images/button/${getImageItemMenu(item.href, true)}`} alt="" />}
                    colorT="#FFFFFF"
                    sx={{
                      ml: 3,
                      backgroundImage: 'url("/images/button/TabActive.png")',
                      width: 300,
                      height: 60,
                      zIndex: 100,
                      borderRadius: 4,
                    }}
                  >
                    {item.label}
                  </CTButtonImage>
                ) : (
                  <CTButtonImage
                    startIcon={<img src={`/images/button/${getImageItemMenu(item.href, false)}`} alt="" />}
                    sx={{
                      ml: 3, backgroundImage: 'url("/images/button/Tab.png")', width: 300, height: 60,
                      zIndex: 100,
                      borderRadius: 4,
                    }}
                    colorT="#A2A3A4"
                    onClick={() => {
                      dispatch(setTabLink(item.href))
                    }}
                  >
                    {item.label}
                  </CTButtonImage>
                )}
              </>
            ))}
          </Box>
        )}
        {!isDesktop && (
          <Box width="1390px" display="flex"   mt="-18vw" justifyContent="center"  maxWidth="100%">
            {menuItems[3].items.map((item) => (
              <>
                {activeSubMenuItem && activeSubMenuItem.href && item.href === tabLink ? (
                  <CTButtonImage
                    startIcon={<img src={`/images/button/${getImageItemMenu(item.href, true)}`} alt="" />}
                    colorT="#FFFFFF"
                    sx={{
                      ml: 3,
                      pl: 3,
                      backgroundImage: 'url("/images/button/TabActiveMobile.png")',
                      width: 175,
                      height: 55,
                      zIndex: 100,
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        color: '#FFFFFF',
                        fontStyle: "normal",
                      }}
                    >
                      {item.label}
                    </Box>
                  </CTButtonImage>
                ) : (
                  <CTButtonImage
                    startIcon={<img src={`/images/button/${getImageItemMenu(item.href, false)}`} alt="" />}
                    sx={{
                      ml: 3,
                      pl: 3,
                      backgroundImage: 'url("/images/button/TabMobile.png")',
                      width: 175,
                      height: 55,
                      zIndex: 100,
                      borderRadius: 2,
                    }}
                    colorT="#A2A3A4"
                    onClick={() => {
                      dispatch(setTabLink(item.href))
                    }}
                  >
                    
                    <Box
                      sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        color: '#FFFFFF',
                        fontStyle: "normal",
                      }}
                    >
                      {item.label}
                    </Box>
                  </CTButtonImage>
                )}
              </>
            ))}
          </Box>
        )}
      </Box>
      <Box sx={{ pl: 3, pr: 3, pb: 10, background: '#000000', position: 'relative' , zIndex: 200 }}  display="flex" justifyContent="center">
        <Box width="1390px" display="flex" maxWidth="100%">
          <Box sx={{ width: '100%' }}>
            <Box         mt={isDesktop ? '30px' : '15px'}
>{tabLink === '/' ? <CoinTools /> : <CreateNFT />}</Box>
          </Box>
        </Box>
      </Box>
      </Box>
    </>
  )
}
