export const slipString = (str: string, length: number) => {
  if (str.length > length) {
    return `${str.slice(0, length)}...`;
  }
  return str;
}

export const formatDay = (createDate: string) => {
  return createDate.split('T')[0]

}

export const slipAddress = (str: string, length: number) => {
  if (str.length > length) {
    return `${str.slice(0, length)}...${str.slice(-4)}`;
  }
  return str;
}