import { Box, Button, DialogProps, FormControlLabel, Link, RadioGroup, Tooltip } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from '@pancakeswap/localization'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import * as React from 'react'
import { useNetwork } from 'wagmi'
import CTRadio from '../../views/Component/CTRadio'
import { CopyIcon } from '../../views/Component/Icon/CopyIcon'
import { DialogsHr } from './style'



const styleText = { display: 'block', fontSize: '14px', color: '#E1E1E1' }
const styleText2 = { display: 'block', fontSize: '14px', color: '#FFFFFF' }


const InfoNFT = ({
  handleOpenDialogWhiteList,
  contract,
  dataForm,
}: {
  open: boolean
  handleClose: (...args: any) => any
  handleOpenDialogWhiteList: (...args: any) => any
  contract: string
  dataForm: any
}) => {
  const { chain } = useNetwork()
  const { t } = useTranslation()
  const { isDesktop } = useMatchBreakpoints()

  const [openTip, setOpenTip] = React.useState(false)
  const [openTipW, setOpenTipW] = React.useState(false)


  const handleTooltipClose = () => {
    setOpenTip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTip(true)
    setTimeout(()=> {
      setOpenTip(false)
    }, 1000)
  }

  const handleTooltipWClose = () => {
    setOpenTipW(false)
  }

  const handleTooltipWOpen = () => {
    setOpenTipW(true)
    setTimeout(()=> {
      setOpenTipW(false)
    }, 1000)
  }

  const copyAddressContract = (addressContract: string) => {
    navigator.clipboard.writeText(addressContract)
    handleTooltipOpen()
  }

  const copyWhiteList = (wList: string) => {
    navigator.clipboard.writeText(wList)
    handleTooltipWOpen()
  }

  const handleLongMetaLink = (link: string) => {
    if (link && link.length > 35) return `${link.slice(0, 25)}...${link.substring(link.length - 3)}`
    return link
  }
  let stylePaperRoot = {}

  if (isDesktop) {
    stylePaperRoot = {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '90%',
          maxWidth: '730px',
        },
      },
    }
  } else {
    stylePaperRoot = {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '90%',
        },
      },
      '& .MuiDialogContent-root': {
        padding: '0px 0px'
      },
    }
  }

  return (
    <>
      <Box sx={stylePaperRoot}>
        <DialogContent dividers>
          <Box display="flex" justifyContent="left" sx={{ pt: 5 }}>
            <Box
              sx={{
                display: 'block',
                color: '#FFFFFF',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {t('NFT Contract information')}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ mt: '32px' }}>
            <Box justifyContent="left" sx={styleText}>
              {t('NFT Name')}
            </Box>
            <Box justifyContent="right" sx={styleText}>
              {dataForm?.name}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
            <Box justifyContent="left" sx={styleText}>
              {t('Symbol')}
            </Box>
            <Box justifyContent="right" sx={styleText}>
              {dataForm?.symbol}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
            <Box justifyContent="left" sx={styleText}>
              {t('Overall Collection Size')}
            </Box>
            <Box justifyContent="right" sx={styleText}>
              {dataForm?.collectionSize}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
            <Box justifyContent="left" sx={styleText}>
              {t('Metadata Link')}
            </Box>
            <Box justifyContent="right" sx={{ ...styleText, wordBreak: 'break-all' }}>
              <Link sx={{ color: '#FFFFFF' }} target="_blank" href={dataForm?.metaLink}>
                {handleLongMetaLink(dataForm?.metaLink)}
              </Link>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ display: 'none', pt: 2 }}>
            <Box justifyContent="left" sx={styleText}>
              {t('Contract')}
            </Box>
            <Box justifyContent="right" sx={{ display: 'flex', fontSize: '14px', color: '#9E9E9E' }}>
              <Box justifyContent="right" display="inline" sx={styleText}>
                <Link
                  sx={{ color: '#FFFFFF' }}
                  target="_blank"
                  href={`${chain?.blockExplorers?.default?.url}/address/${contract}`}
                >
                  <Box sx={{ wordBreak: 'break-all', textAlign: 'right' }}>{contract}</Box>
                </Link>
              </Box>
              <Box justifyContent="right" sx={{ mt: -0.8, ml: 1, display: 'block' }}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={openTip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Copied"
                >
                  <IconButton
                    onClick={() => {
                      copyAddressContract(contract)
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box sx={{ pt: 2, mb: 2 }}>
            <DialogsHr />
          </Box>
          <Box display="flex" justifyContent="left" sx={{ pt: 1 }}>
            <Box
              sx={{
                display: 'block',
                color: '#FFFFFF',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {t('Other Settings')}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', pt: 2 }}>
            <RadioGroup>

              <FormControlLabel
                value="female"
                color="#ffffff"
                control={
                  <CTRadio
                    sx={{
                      color: '#9E9E9E',
                    }}
                    checked={dataForm?.enablePublicMint}
                    disabled
                    value="a"
                    name="radio-buttons"
                    size="small"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
                label={<Box sx={!dataForm?.enablePublicMint ? styleText2 : styleText2}>{t('Enable Public Minting')}</Box>}
              />
            </RadioGroup>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <RadioGroup>
              <FormControlLabel
                value="female"
                color="#ffffff"
                control={
                  <CTRadio
                    sx={{
                      color: '#9E9E9E',
                    }}
                    checked={dataForm?.enableWhiteList}
                    disabled
                    name="radio-buttons"
                    size="small"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
                label={<Box sx={!dataForm?.enableWhiteList ? styleText2 : styleText2}>{t('Accept White List')}</Box>}
              />
            </RadioGroup>

          </Box>
          {dataForm?.enableWhiteList && (
            <>
              <Box display="flex" justifyContent="space-between" sx={{ pt: 1 }}>
                <Box justifyContent="left" sx={{ ...styleText2, ml: 4 }}>
                  <Box display="inline" pr="5px">
                    &#x2022;
                  </Box>
                  {t('Mint price')}
                </Box>
                <Box justifyContent="right" sx={{ ...styleText2, mr: 4 }}>
                  {dataForm?.mintPrice}
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
                <Box justifyContent="left" sx={{ ...styleText, ml: 4, pt: 1 }}>
                  <Box display="inline" pr="5px">
                    &#x2022;
                  </Box>
                  {t('Whitelist Address')}
                </Box>
                <Box justifyContent="right" sx={{ display: 'flex', fontSize: '14px', color: '#9E9E9E' }}>
                  <Box justifyContent="right" sx={{ mt: -0.8, ml: 1, display: 'block' }}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipWClose}
                      open={openTipW}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Copied"
                    >
                      <IconButton
                        onClick={() => {
                          copyWhiteList(dataForm?.whiteList)
                        }}
                      >
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box justifyContent="right" display="inline" sx={{ ...styleText, mr: 2.5 }}>
                    <Button
                      sx={{
                        color: '#FFFFFF',
                        textDecoration: 'underline',
                        textTransform: 'capitalize',
                        fontSize: '14px',
                        lineHeight: '16.71px',
                      }}
                      variant="text"
                      onClick={handleOpenDialogWhiteList}
                    >
                      {t('Detail')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
      </Box>
    </>
  )
}

export default InfoNFT
