import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'

const CTButtonLearnMore = styled(LoadingButton)<{ loadingLeft?: any } & LoadingButtonProps>(
  ({  loadingLeft }) => ({
    textTransform: 'none',
    fontSize: '18px',
    borderRadius: '8px',
    border: '1px solid var(--Grey-Grey50, #7E7E7E)',
    boxShadow: '0px 4px 44px 0px rgba(143, 101, 59, 0.20)',
    '&.MuiButtonBase-root:hover': {},
    '&.MuiButtonBase-root': {
      backgroundColor: `transparent`,
      color: '#9E9E9E',
      border: '0 solid',
      boxSizing: 'border-box',
      borderStyle: 'solid',
      '--tw-border-opacity': '1',
      borderColor: '#E0E0E0',
      borderWidth: '1px',
      '--tw-bg-opacity': '0.8',
      '&.MuiInputBase-root fieldset': {
        borderColor: `#e0e0e0ee`,
        color: `#D1D1D1`,
      },
      '& .MuiFormHelperText-root': {
        color: `#D1D1D1 !important`,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: `#D1D1D1 !important`,
      },
      '& .MuiLoadingButton-loadingIndicator': {
        left: loadingLeft,
      },
      '&.MuiLoadingButton-root.Mui-disabled': {
        color: '#FFFFFF !important',
      },
    },
  }),
)

export default CTButtonLearnMore
