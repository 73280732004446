import Radio, { RadioProps } from '@mui/material/Radio'
import { styled } from '@mui/material/styles'

const CTRadio = styled(Radio)<RadioProps>(() => ({
  '&.MuiButtonBase-root': {
    color: '#9E9E9E',
    '&.Mui-checked': {
      color: '#E8CC93',
    },
  },
}))


export default CTRadio
