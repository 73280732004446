import TextField, { TextFieldProps } from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const CTTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#9E9E9E',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
    color: '#9E9E9E',
    opacity: 1,
  },
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root fieldset': {
      borderColor: `#e0e0e0ee`,
      color: `#D1D1D1`,
    },
    '& .MuiFormHelperText-root': {
      color: `#D1D1D1 !important`,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: `#D1D1D1 !important`,
    },
  },
}))

export default CTTextField
