import { Box, Link } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from '@pancakeswap/localization';
import { useMatchBreakpoints } from '@pancakeswap/uikit';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import CTButtonImage from 'views/Component/CTButtonImage';
import { OpenLinkIcon } from 'views/Component/Icon/OpneLinkIcon';
import { useAccount, useNetwork } from 'wagmi';

const PageHr = styled.hr`
border: none;
height: 1px;
color: #FFFFFFF; /* old IE */
background-color: #ffffff; /* Modern Browsers */
`

const StyledTableCell = styled(TableCell)(() => ({
 
  [`&.${tableCellClasses.head}`]: {
    borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
    color: '#7E7E7E',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  color: 'red',
  borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
  '&:nth-of-type(odd)': {
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  'td, th':{
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
  }
}));

export default function HistoryTokenTable() {
  const { t } = useTranslation()
  const { isDesktop } = useMatchBreakpoints()

  const { chain } = useNetwork()

  const { address: account } = useAccount()
  const [page, setPage] = React.useState(1)
  const [limit, setlimit] = React.useState(10)
  const [total, setTotal] = React.useState(1)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  
  useEffect(() => {
    getHistory()
  }, [page, account]);

  const getHistory = () => {
    setLoading(true)
    const requestOptions = {
      method: 'GET',
    }

    fetch(
      `${process.env.NEXT_PUBLIC_LOG_SERVER}/api/token/list?page=${page}&limit=${limit}&wallet=${account}`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.items.list)
        setTotal(result.items.total)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('error', error)
      })
  }

  const goNext = () => {
    setPage(page + 1)
  }

  const goBack = () => {
    setPage(page - 1)
  }


  const getCountPage = () => {
    return Math.ceil(total / limit)
  }
  return (
    <>
        <Box
          pb="10px"
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Box display="flex"  justifyContent="center">
            <Box
              pb="30px"
              mt="50px"
              justifyContent="center"
              id='historyToken'
              sx={{
                textAlign: 'center',
                fontSize: '32px',
                color: '#FFFFFF',
              }}
            >
              {t('History')}
            </Box>
          </Box>
          <PageHr />
        </Box>
    <TableContainer component={Paper} sx={{mt: 3, backgroundColor: 'transparent', color: '#ffffff'}}>
      <Table sx={{ color: '#ffffff' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{backgroundColor: 'transparent', display: !isDesktop? "none" : ""}}>{t('No.')} </StyledTableCell>
            <StyledTableCell sx={{backgroundColor: 'transparent'}} >{t('Token Name')}</StyledTableCell>
            <StyledTableCell sx={{backgroundColor: 'transparent'}} >{t('Symbol')}</StyledTableCell>
            <StyledTableCell sx={{backgroundColor: 'transparent'}} >{t('Supply')}</StyledTableCell>

            <StyledTableCell sx={{backgroundColor: 'transparent'}} >{t('Created at')}</StyledTableCell>
            <StyledTableCell sx={{backgroundColor: 'transparent', textAlign: 'right'}} >{t('Contract address')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <StyledTableRow key={row.transactionHash}>
              <StyledTableCell  sx={{backgroundColor: 'transparent', color: '#ffffff', display: !isDesktop? "none" : ""}} >{1 + index + (page -1) * limit }</StyledTableCell>
              <StyledTableCell sx={{backgroundColor: 'transparent', color: '#ffffff'}} component="th" scope="row">
                {row.tokenName}
              </StyledTableCell>
              <StyledTableCell sx={{backgroundColor: 'transparent', color: '#ffffff'}}  >{row.symbol}</StyledTableCell>
              <StyledTableCell sx={{backgroundColor: 'transparent', color: '#ffffff'}}  >{row.supply}</StyledTableCell>
              <StyledTableCell sx={{backgroundColor: 'transparent', color: '#ffffff'}} >{row.createDate.split('T')[0].replaceAll('-','/')}</StyledTableCell>
              <StyledTableCell sx={{backgroundColor: 'transparent', color: '#ffffff',  textAlign: 'right'}} ><Link 
                  sx={{ mt: 1, color: '#FFFFFF' }}
                  target="_blank"
                  href={`${chain?.blockExplorers?.default?.url}/address/${row.contractAddress}`}
                > <Box
                sx={{
                  display: 'inline',
                  fontStyle: 'normal',
                  whiteSpace: 'nowrap'
                 }}
              >
                {row.contractAddress}
              <Box sx={{ml: 1, display: 'inline', verticalAlign:"top"}} ><OpenLinkIcon  /></Box></Box></Link></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Box display="flex" justifyContent="center">
          <Box sx={{ pt: 10, pr: 2 }} display="flex" width="100%" maxWidth="1390px" justifyContent="space-between">
            <Box />
            <Box>
              {page > 1 && (
                <CTButtonImage
                  colorT="#FFFFFF"
                  sx={{
                    backgroundImage: 'url("/images/button/BackPage.png")',
                    backgroundPosition: '50% 45%',
                    width: 50,
                    height: 50,
                  }}
                  onClick={goBack}
                  loading={false}
                />
              )}
              {page < total / limit && (
                <CTButtonImage
                  colorT="#FFFFFF"
                  onClick={goNext}
                  sx={{
                    backgroundImage: 'url("/images/button/NextPage.png")',
                    backgroundPosition: '50% 45%',
                    width: 210,
                    height: 50,
                    textTransform: 'uppercase',
                  }}
                  loading={false}
                  loadingPosition="start"
                  loadingLeft="20px"
                >
                  <Box
                    mr="10px"
                    sx={{
                      mr: 2,
                      fontSize: '16px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      color: '#FFFFFF',
                    }}
                  >
                    {t('Next Page')}
                  </Box>
                  <Box display="contents">
                    <img src="/images/button/arrow-left.png" alt="" />
                  </Box>
                </CTButtonImage>
              )}
            </Box>
            <Box display="flex" justifyContent="left">
              <Box
                sx={{
                  height: 50,
                  mt: 2,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                {t('Page')}
              </Box>
              <Box>
                <CTButtonImage
                  colorT="#FFFFFF"
                  sx={{
                    backgroundImage: 'url("/images/button/NumberPage.png")',
                    width: 50,
                    height: 50,
                    textTransform: 'uppercase',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      color: '#FFFFFF',
                    }}
                  >
                    {page}
                  </Box>
                </CTButtonImage>
              </Box>
              <Box
                sx={{
                  height: 50,
                  mt: 2,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                {t('of')} {getCountPage()}
              </Box>
            </Box>
          </Box>
        </Box>
    </>
  );
}