export const InfoIcon = ({ ...props }) => {
  return (
    <svg
      width={props.width ? props.width : '16'}
      height={props.height ? props.height : '17'}
      viewBox="0 0 16 17"
      fill={props.fill ? props.fill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.2266C6.14348 15.2266 4.36301 14.4891 3.05025 13.1763C1.7375 11.8636 1 10.0831 1 8.22656C1 6.37005 1.7375 4.58957 3.05025 3.27681C4.36301 1.96406 6.14348 1.22656 8 1.22656C9.85652 1.22656 11.637 1.96406 12.9497 3.27681C14.2625 4.58957 15 6.37005 15 8.22656C15 10.0831 14.2625 11.8636 12.9497 13.1763C11.637 14.4891 9.85652 15.2266 8 15.2266ZM8 16.2266C10.1217 16.2266 12.1566 15.3837 13.6569 13.8834C15.1571 12.3831 16 10.3483 16 8.22656C16 6.10483 15.1571 4.07 13.6569 2.56971C12.1566 1.06942 10.1217 0.226563 8 0.226562C5.87827 0.226563 3.84344 1.06942 2.34315 2.56971C0.842855 4.07 0 6.10483 0 8.22656C0 10.3483 0.842855 12.3831 2.34315 13.8834C3.84344 15.3837 5.87827 16.2266 8 16.2266Z"
        fill="#D1D1D1"
        fillOpacity="0.819608"
      />
      <path
        d="M7.00195 11.2257C7.00195 11.0944 7.02782 10.9643 7.07807 10.843C7.12833 10.7217 7.20199 10.6115 7.29485 10.5186C7.38771 10.4257 7.49794 10.3521 7.61927 10.3018C7.7406 10.2516 7.87063 10.2257 8.00195 10.2257C8.13328 10.2257 8.26331 10.2516 8.38464 10.3018C8.50596 10.3521 8.6162 10.4257 8.70906 10.5186C8.80192 10.6115 8.87558 10.7217 8.92583 10.843C8.97609 10.9643 9.00195 11.0944 9.00195 11.2257C9.00195 11.4909 8.8966 11.7453 8.70906 11.9328C8.52152 12.1203 8.26717 12.2257 8.00195 12.2257C7.73674 12.2257 7.48238 12.1203 7.29485 11.9328C7.10731 11.7453 7.00195 11.4909 7.00195 11.2257ZM7.09995 5.2207C7.08664 5.09454 7.09999 4.967 7.13915 4.84633C7.17831 4.72567 7.24241 4.61458 7.32727 4.52029C7.41214 4.426 7.51588 4.3506 7.63176 4.29899C7.74765 4.24737 7.87309 4.2207 7.99995 4.2207C8.12681 4.2207 8.25226 4.24737 8.36814 4.29899C8.48403 4.3506 8.58777 4.426 8.67263 4.52029C8.7575 4.61458 8.82159 4.72567 8.86075 4.84633C8.89991 4.967 8.91327 5.09454 8.89995 5.2207L8.54995 8.7277C8.53819 8.86547 8.47516 8.99382 8.37331 9.08734C8.27146 9.18086 8.13823 9.23276 7.99995 9.23276C7.86168 9.23276 7.72844 9.18086 7.6266 9.08734C7.52475 8.99382 7.46171 8.86547 7.44995 8.7277L7.09995 5.2207Z"
        fill="#D1D1D1"
        fillOpacity="0.819608"
      />
    </svg>
  )
}
