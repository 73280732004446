export const OpenLinkIcon = ({ ...props }) => {
  return (
    <svg
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '24'}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 3H21V10M21 14.737V19.5C21 19.8978 20.842 20.2794 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H9M12.9 11.1L20.55 3.45" stroke="#B1B1B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> 
    </svg>
  )
}
